import React from 'react'
import { Icon as MUIIcon } from '@mui/material'

MUIIcon.name = 'Icon'

const Icon = React.forwardRef(
  (
    {
      name = 'notdef',
      spin = false,
      className = '',
      baseClassName = 'fas',
      ...props
    },
    ref
  ) => (
    <MUIIcon
      ref={ref}
      {...props}
      className={`${className} fa-${name} fa-fw ${spin ? 'fa-spin fa-spin-pulse' : ''}`}
      baseClassName={baseClassName}
    />
  )
)

export default Icon
