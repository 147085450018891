import { useEffect, useRef } from 'react'

import useSupabase from './useSupabase'
import { useSession as useSessionState, useSetSession } from 'state/useSession'

const useSession = () => {
  const supabase = useSupabase()
  const session = useSessionState()
  const setSession = useSetSession()
  const loading = useRef(true)

  useEffect(() => {
    if (!loading.current) return
    ;(async () => {
      const {
        data: { session }
      } = await supabase.auth.getSession()

      loading.current = false
      if (!session) return

      const { data: mfa } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel()

      setSession({
        ...session,
        mfa,
      })
    })()
  }, [])

  supabase.auth.onAuthStateChange((event, session) => {
    setSession(session)
  })

  const signin = () =>
    supabase.auth.signInWithOAuth({
      provider: 'google',
    })

  const signout = () => supabase.auth.signOut()

  return { loading: loading.current, session, signin, signout }
}

export default useSession
