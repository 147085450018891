const scrollTo = (id, delay = 0) => {
  setTimeout(() => {
    const element = document.getElementById(id)

    const headerOffset = 90
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset

    document.getElementById('content').scrollBy({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }, delay)
}

export default scrollTo
