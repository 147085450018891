import { Card, CardContent, CardHeader, styled, Typography } from '@mui/material'

import Icon from 'components/Icon'
import { useLoading as use_products_loading } from 'state/useProductsStore'
import { useLoading as use_customers_loading } from 'state/useCustomersStore'

const StyledDialog = styled('dialog')`
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  padding: 0;
  z-index: 10000;

  &::backdrop {
    background: transparent;
  }
`;

const CustomBackdrop = styled('div')`
  /* Full-screen backdrop */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 10000;
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  align-items: center;
`

const Indicator = ({ loaded }) => {
  if ( loaded ) {
    return <Icon style={{ color: 'var(--mui-palette-Alert-successFilledBg)'}} name='circle-check' />
  }

  return <Icon style={{ color: 'var(--mui-palette-primary-main)'}} name='spinner' spin />
}

export const LoadingProgress = () => {
  const products_loading = use_products_loading()
  const customers_loading = use_customers_loading()
  
  return (
    <>
      <CustomBackdrop />
      <StyledDialog open>
        <Card elevation={10}> 
          <CardHeader
            title="Loading"
            subheader="Initiating the application data, please wait..."
          />
          <CardContent>
            <Grid>
              <Typography>Customers</Typography><Indicator loaded={ !customers_loading } />
              <Typography>Products</Typography><Indicator loaded={ !products_loading } />
            </Grid>
          </CardContent>
        </Card>
      </StyledDialog>
    </>
  )
}

export const LoadingError = () => (
  <>
    <CustomBackdrop />
    <StyledDialog open>
      <Card elevation={10}> 
        <CardHeader
          title="Oops! Something Went Wrong"
          subheader="We're sorry, but we couldn't load your data from our servers."
        />
        <CardContent>
          <Typography variant="body1" mb={2}>Don't worry, your information is safe; we just couldn't retrieve it at the moment.</Typography>
          <Typography variant="h6">What Can You Do?</Typography>
          <Typography variant="body2" element="ul" mb={1} mt={1}>
              <li>Check Your Internet Connection: Sometimes these issues are due to network hiccups.</li>
              <li>Refresh the Page: A simple refresh can often solve the issue.</li>
              <li>Try Again Later: Our servers might be experiencing high traffic or undergoing maintenance.</li>
          </Typography>
          <Typography variant="body1">If the issue persists, please contact our support for further assistance.</Typography>
        </CardContent>
      </Card>
    </StyledDialog>
  </>
)


