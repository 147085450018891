import { useState } from 'react'
import {
  Drawer,
  InputAdornment,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { useHotkeys } from 'react-hotkeys-hook'

import Fab from 'components/Fab'
import Icon from 'components/Icon'
import Field from 'components/Field'
import Grid from 'components/Grid'

import Card from './Card'
import Form from './Form'
import { useCustomers } from 'state/useCustomersStore'

const AnimatedCard = styled(Card)`
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  &:hover {
    transform: translateY(-0.1rem);
    box-shadow: var(--mui-shadows-3);
  }
`

const Customers = () => {
  const customers = useCustomers()
  const [customer, setCustomer] = useState({})
  const [filter, setFilter] = useState('')
  const [drawerOpen, setDrawerOpen] = useState(false)

  const ref = useHotkeys(
    'esc',
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setFilter('')
    },
    { enableOnTags: ['INPUT'] }
  )

  return (
    <>
      <Stack direction='row' spacing={4} mb={4} justifyContent='space-between'>
        <Typography variant='h4'>Customers</Typography>
        <Field
          ref={ref}
          autoFocus
          label='Filter'
          value={filter}
          update={(value) => setFilter(value)}
          variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <Icon name='search' />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Grid>
        {customers
          .filter(
            (customer) =>
              !filter ||
              customer.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((customer) => (
            <AnimatedCard key={customer.id} {...customer} />
          ))}
      </Grid>

      <Drawer
        anchor='bottom'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{ sx: { p: '2em 3em 5em' } }}
      >
        <Typography variant='h4' mb={4}>
          Create new customer
        </Typography>
        <Form
          customer={customer}
          update={(change) => setCustomer({ ...customer, ...change })}
        />
        <Stack mt={2} direction='row' spacing={2} justifyContent='flex-end'>
          <Fab
            aria-label='save'
            onClick={() => {
              throw 'Implementation missing'
            }}
          >
            <Icon name='save' />
          </Fab>
        </Stack>
      </Drawer>

      <Fab aria-label='add' onClick={() => setDrawerOpen(true)}>
        <Icon name='add' />
      </Fab>
    </>
  )
}

export default Customers
