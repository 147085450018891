import { IconButton, Stack, Tooltip } from '@mui/material'
import { styled, useColorScheme } from '@mui/material/styles'

import Icon from 'components/Icon'

const StackLine = styled(Stack)`
  position: relative;
  align-self: center;
  width: 100%;
  &::before,
  &::after {
    position: absolute;
    top: calc(50% - 1px);
    content: '';
    width: calc(50% - 18px);
    height: 2px;
    background: ${({ mode }) => (mode === 'light' ? '#bbb' : '#555')};
    opacity: ${({ background }) => (background === 'light' ? 0.5 : 1)};
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
`
const Button = styled(IconButton)`
  border: 1px solid ${({ mode }) => (mode === 'light' ? '#bbb' : '#444')};
  background: ${({ mode }) => (mode === 'light' ? '#ccc' : '#555')};
  transition: background-color 250ms;
  opacity: ${({ background }) => (background === 'light' ? 0.5 : 1)};
  aspect-ratio: 1;
  &:hover {
    background: ${({ mode }) => (mode === 'light' ? '#bbb' : '#444')};
  }
`

const LineAdd = ({ tooltip = 'Add', background = 'light', onClick }) => {
  const { mode } = useColorScheme()
  return (
    <StackLine
      mode={mode}
      background={background}
      direction='row'
      justifyContent='center'
    >
      <Tooltip title={tooltip} placement='top' arrow>
        <Button mode={mode} background={background} onClick={onClick}>
          <Icon name='add' />
        </Button>
      </Tooltip>
    </StackLine>
  )
}

export default LineAdd
