import { Avatar, Chip, Stack, Typography } from '@mui/material'
import { format, formatDistance, parseJSON } from 'date-fns'

const relative_time = (timestamp) => formatDistance(
  parseJSON(timestamp),
  new Date(),
  {
    addSuffix: true,
  }
)

const absolute_time = (timestamp) => format(parseJSON(timestamp), 'yyyy-MM-dd HH:mm')

const Summary = ({ event, versions, relative = true }) => (
    <Stack gap={2} alignItems='end'>
      <Typography variant='body2'>
        {versions > 0 ? `Last updated ${relative ? relative_time(event.updated_at) : absolute_time(event.updated_at)}` : '\u00A0'}
      </Typography>
      
      <Typography variant='body2'>
        {`Created ${relative ? relative_time(event.created_at) : absolute_time(event.created_at)}`}
      </Typography>
      
      {event.account &&
        <Chip
          avatar={<Avatar alt={event.account.name} src={event.account.avatar_url} />}
          label={event.account.name}
          sx={{
            backgroundColor: 'var(--mui-palette-secondary-light)',
            border: '1px solid var(--mui-palette-secondary-main)',
          }}
        />
      }
    </Stack>
  )

export default Summary
