import { v4 as uuid } from 'uuid'
import { Card, CardContent, CardHeader, IconButton } from '@mui/material'

import SectionHeader from 'components/SectionHeader'

import useDebugState from '../../state/debug'
import CommonFields from '../CommonFields'
import { Editor } from './Editor'

const Calculation = ({
  id = uuid(),
  attributes = {},
  onChange,
  onDelete,
  ...commonOptions
}) => {
  const { state } = useDebugState()

  return (
    <>
    <SectionHeader
      icon='code'
      title={commonOptions.name || 'Calculation'}
      subtitle='Define a new variable that will be used when defining the product instance for the end user.'
    />
    <Card id={id}>
      <CardContent>
        <CommonFields {...commonOptions} onChange={onChange} />
        <Editor
          {...attributes}
          onChange={(change) =>
            onChange({ attributes: { ...attributes, ...change } })
          }
          state={state}
        />
      </CardContent>
      </Card>
      </>
  )
}

export default Calculation
