import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { collection } from 'utilities/routes'

const useProductsStore = create((set) => {
  return {
    loading: true,
    products: [],
    fetchProducts: async () => {
      const products = await collection({
        path: 'products',
        builder: (query) => query.order('created_at', { ascending: true }),
      })

      set({ products, loading: false })
    },
    addProduct: (product) => set(({ products }) => ({ products: [...products, product ] })),
    updateProduct: (updated) => set((state) => ({
      products: state.products.map(product => 
        product.id === updated.id ? { ...product, ...updated } : product
      )
    })),
    deleteProduct: ({ id }) => set((state) => ({
      products: state.products.filter(product => product.id !== id)
    })),
  }
})

export const useLoading = () => useProductsStore((state) => state.loading)
export const useProducts = () => useProductsStore((state) => state.products)
export const useProduct = (id) => useProductsStore((state) => state.products.find(product => product.id === id))

export const addProduct = useProductsStore.getState().addProduct
export const updateProduct = useProductsStore.getState().updateProduct
export const deleteProduct = useProductsStore.getState().deleteProduct
export const fetchProducts = useProductsStore.getState().fetchProducts
export default useProductsStore

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Products', useProductsStore);
}
