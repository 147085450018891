import { Button, Card, CardActions, CardContent, Collapse, Grid, IconButton } from '@mui/material'
import { Form } from 'react-router-dom'

import Field from 'components/Field'
import ImageUpload from 'components/ImageSelect'
import SectionHeader from 'components/SectionHeader'
import Icon from 'components/Icon'
import { useCurrentCustomer } from 'state/useCustomersStore'

const General = ({ expanded, set_expanded }) => {
  const customer = useCurrentCustomer()

  return (
    <Form id="general" method='post'>
      <SectionHeader
        id='company-info'
        title='Company info'
        subtitle='Legal information about the company'
        icon='square-info'
        action={
          <IconButton
            onClick={() => set_expanded(!expanded)}
            aria-expanded={expanded}
            aria-label='show more'
            size='small'
          >
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </IconButton>
        }
      />
    <Card id={customer.id}>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Field
                label='Common name'
                helperText='Colloquial company name, like "Accodeing"'
                defaultValue={customer.name}
                name="name"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label='Legal name'
                helperText='Legal company name, like "Accodeing to you KB"'
                defaultValue={customer.legal_name}
                name="legal_name"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label='Organisational number'
                helperText='The TIN number for the legal entity, for example "969786-4776"'
                defaultValue={customer.number}
                name="number"
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                multiline
                minRows={3}
                label='Description'
                helperText='Description of the company and its focus'
                defaultValue={customer.description}
                name="description"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label='Owner names'
                helperText='First and last name. Comma seperate several owners'
                defaultValue={customer.owners.join(', ')}
                name="owners"
              />
            </Grid>
            <Grid item xs={12}>
              <ImageUpload
                name='logo_url'
                url={customer.logo_url}
                path={customer.name}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      <CardActions sx={{ justifyContent: 'end'}}>
        <Button type="submit" name="section" value="general" variant="text" color="secondary" size="small">Save</Button>
      </CardActions>
      </Card>
    </Form>
  )
}

export default General
