import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import Fab from 'components/Fab'
import Grid from 'components/Grid'
import Icon from 'components/Icon'

import Card from './Card'
import { useProducts } from 'state/useProductsStore'

const Products = () => {
  const navigate = useNavigate()
  const products = useProducts()
  const root_products = products.filter((p) => !p.parent)
  const addons = products.filter((p) => p.parent)

  return (
    <motion.article
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >
      <Grid>
        {root_products.map((product) => {
          const productAddons = addons.filter((p) => p.parent === product.id)
          return <Card key={product.id} {...product} addons={productAddons} />
        })}
      </Grid>
      <Fab aria-label='add' onClick={() => navigate(`./${uuid()}`)}>
        <Icon name='plus' />
      </Fab>
    </motion.article>
  )
}

export default Products
