import { useState } from 'react'
import { useParams } from 'react-router-dom'
import useSupabase from './useSupabase'

const useCustomerHistory = () => {
  const [history, setHistory] = useState([])
  const supabase = useSupabase()
  const { id } = useParams()

  if (!id) return history
  if (!window.location.pathname.startsWith('/customers/')) return history

  // TODO: Use Zustand store
  supabase
    .from('customers')
    .select('*')
    .eq('id', id)
    .maybeSingle()
    .then(({ data, error }) => {
      const filteredHistory = history.filter((item) => item.id !== id)
      const newHistory = [data, ...filteredHistory.slice(0, 2)]

      if (
        newHistory.map((i) => i.id).join('') ===
        history.map((i) => i.id).join('')
      )
        return

      setHistory([data, ...filteredHistory.slice(0, 2)])
    })

  return history
}

export default useCustomerHistory
