import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { useParams } from 'react-router-dom'

import { collection } from 'utilities/routes'

const useContactsStore = create((set) => {
  return {
    loading: true,
    contacts: [],
    fetchContacts: async () => {
      const contacts = await collection({
        path: 'contacts',
        builder: (query) => query.order('created_at', { ascending: true }),
      })
      set({ contacts, loading: false })
    },
    addContact: (contact) => set(({ contacts }) => ({ contacts: [...contacts, contact ] })),
    updateContact: (updated) => set((state) => ({
      contacts: state.contacts.map(contact => 
        contact.id === updated.id ? { ...contact, ...updated } : contact
      )
    })),
    deleteContact: ({ id }) => set((state) => ({
      contacts: state.contacts.filter(contact => contact.id !== id)
    })),
  }
})

export const useLoading = () => useContactsStore((state) => state.loading)
export const useContacts = () => useContactsStore((state) => state.contacts)
export const useCurrentContact = () => {
  const { contact_id } = useParams()
  return useContactsStore((state) => state.contacts.find(contact => contact.id === contact_id)) || {}
}
export const useCustomerContacts = (id) => useContactsStore((state) => state.contacts.filter(contact => contact.customer_id === id))

export const addContact = useContactsStore.getState().addContact
export const updateContact = useContactsStore.getState().updateContact
export const deleteContact = useContactsStore.getState().deleteContact
export const fetchContacts = useContactsStore.getState().fetchContacts
export default useContactsStore

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Constacts', useContactsStore);
}
