import { redirect } from 'react-router-dom'

import { supabase } from 'hooks/useSupabase'
import { getSession } from 'state/useSession'
import { upsert, get_form_data } from 'utilities/routes'
import CustomerEvents from 'pages/Customer/Events'
import Modal from 'pages/Customer/Events/Modal'

const action = async ({ params, request }) => {
  const [ data ] = await get_form_data(request)

  const payload = {
    id: params.id,
    ...data,
  }

  await upsert('customers', payload)

  return null
}

const event_action = async ({ params, request }) => {
  const [data] = await get_form_data(request)
  const session = getSession()
  
  const event_payload = {
    id: params.event_id,
    customer_id: params.id,
    title: data.title,
  }

  const note_payload = {
    event_id: params.event_id,
    account: session.user.id,
    notes: data.notes,
    type: data.type,
  }

  await upsert('events', event_payload)
  await supabase
    .from('event_notes')
    .insert([note_payload]);

  return redirect(`/customers/${params.id}/events`)
}

const route = {
  path: ':id/events',
  element: <CustomerEvents />,
  action,
  children: [
    {
      path: ':event_id',
      element: <Modal open />,
      action: event_action,
    },
  ],
}

export default route
