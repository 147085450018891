import { useParams, Await } from 'react-router-dom'

import { useCurrentContact } from 'state/useContactsStore'

import Main from './Main'

const Modal = () => {
  const contact = useCurrentContact()

  return (
    <Main contact={contact} />
  )
}

export default Modal
