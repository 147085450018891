import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Icon from 'components/Icon'

import CopyableAttribute from './CopyableAttribute'

const Grid = styled('section')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'b  a'
    'c  c';
  grid-gap: 1em;
  @container (inline-size < 500px) {
    .my-grid {
      grid-template-areas:
        'a  a'
        'b  b'
        'c  c';
    }
  }
`

const Logo = styled('img')`
  position: relative;
  grid-area: a;
  object-fit: contain;
  object-position: center right;
  max-width: 100%;
  aspect-ratio: 3 / 1;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: var(--mui-palette-quinary-light);
    border: 2px solid var(--mui-palette-quinary-main);
  }
  &::after {
    content: attr(alt);
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mui-palette-quinary-dark);
  }
`

const Header = styled('div')`
  grid-area: b;
  display: flex;
  flex-direction: column;
`

const HeaderRow = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  & span:first-of-type {
    opacity: 0.5;
    width: 3rem;
    text-align: right;
  }
`

const Description = styled('div')`
  grid-area: c;
`

const Customer = ({
  id,
  name,
  legal_name,
  number,
  vat,
  description,
  logo_url,
  className,
}) => {
  const navigate = useNavigate()
  const active_page = window.location.pathname === `/customers/${id}`

  return (
    <Card
      className={className}
      style={{
        cursor: active_page ? 'auto' : 'pointer',
        containerType: 'inline-size',
      }}
    >
      <CardContent
        onClick={() => {
          navigate(`/customers/${id}`)
        }}
      >
        <Grid className='my-grid'>
          <Logo src={logo_url} alt={`${name} logo`} />
          <Header>
            <Typography variant='h5' mb={0} noWrap>
              {name || '-'}
            </Typography>
            <Typography variant='subtitle1' pt={0} noWrap>
              {legal_name || '-'}
            </Typography>
            <HeaderRow>
              <CopyableAttribute title='Org.No' value={number} />
            </HeaderRow>
            <HeaderRow>
              <CopyableAttribute title='VAT' value={vat} />
            </HeaderRow>
          </Header>
          <Description>
            <Divider />
            <Typography variant='body' paragraph mt={2} sx={{minHeight: '1.5rem'}}>
              {description}
            </Typography>
          </Description>
        </Grid>
      </CardContent>
      <CardActions>
        <Tooltip title='Products and services'>
          <IconButton
            onClick={() => {
              navigate(`/customers/${id}/products`)
            }}
            aria-label='Products and services'
          >
            <Icon name='boxes-packing' />
          </IconButton>
        </Tooltip>

        <Tooltip title='Communication log'>
          <IconButton
            aria-label='Communication log'
            onClick={() => {
              navigate(`/customers/${id}/events`)
            }}
          >
            <Icon name='messages' />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

export default React.memo((props) => <Customer {...props} />)
