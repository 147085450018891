function transform_to_nested(obj) {
  const result = {};

  for (const [key, value] of Object.entries(obj)) {
    const match = key.match(/^(\w+)\[(\d+)\](.*)$/);

    if (match) {
      const [_, name, index, rest] = match;

      if (!result[name]) {
        result[name] = [];
      }

      if (rest.startsWith('.')) {
        const new_key = rest.slice(1); // Remove the leading '.'

        // Check if an object already exists at that index
        if (!result[name][index]) {
          result[name][index] = {};
        }

        // Merge the new data into the existing object
        Object.assign(result[name][index], transform_to_nested({ [new_key]: value }));
      } else {
        result[name][index] = value;
      }
    } else {
      result[key] = value;
    }
  }

  return result;
}

const get_form_data = async (request) => {
  const form_data = await request.formData()
  const { section, ...data } = Object.fromEntries(form_data)
  return [ transform_to_nested(data), section ]
}

export default get_form_data
