import React from 'react'
import { useParams } from 'react-router-dom'
import { Stack } from '@mui/material'

import TableOfContent from 'components/TableOfContent'

import SideNavigationButton from 'components/SideNavigationButton'

const SideNavigation = ({ items }) => {
  const { id } = useParams()
  return (
    <div style={{ position: 'sticky', top: '2rem' }}>
      <Stack spacing={1}>
        <SideNavigationButton
          url={`/customers/${id}/products`}
          label='Products and services'
          icon='boxes-packing'
        />
        <SideNavigationButton
          url={`/customers/${id}/events`}
          label='Communication log'
          icon='messages'
        />
      </Stack>
      <TableOfContent title='Sections' items={items} />
    </div>
  )
}

export default SideNavigation
