import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material'

import Avatar from './Avatar'

const OrgMenu = ({ organisations, anchorEl, open, onClose }) => (
  <Menu
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    onClick={onClose}
    PaperProps={{
      elevation: 0,
      sx: {
        'bgcolor': 'secondary.main',
        'color': '#fff',
        'minWidth': '200px',
        'overflow': 'visible',
        'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        'mt': 1.5,
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: 'secondary.main',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  >
    <MenuList>
      {organisations.map((organisation) => (
        <MenuItem dense key={organisation.id}>
          <ListItemIcon>
            <Avatar
              sx={{ border: '1px solid #fff8' }}
              src={organisation.logo_url}
              alt={`${organisation.name}'s logotype`}
            />
          </ListItemIcon>
          <ListItemText>{organisation.name}</ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  </Menu>
)

export default OrgMenu
