import { TextField } from '@mui/material'
import { forwardRef } from 'react'

const Field = forwardRef(({ update = () => { }, InputLabelProps = {}, ...args }, ref) => (
  <TextField
    inputRef={ref}
    fullWidth
    margin='dense'
    size='small'
    variant='filled'
    onChange={({ target: { value } }) => update(value)}
    {...args}
    InputLabelProps={{
      shrink: true,
      ...InputLabelProps,
    }}
  />
))

Field.displayName = 'Field'

export default Field
