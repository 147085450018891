import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { useParams } from 'react-router-dom'

import { collection } from 'utilities/routes'

const useEventsStore = create((set) => {
  return {
    loading: true,
    events: [],
    fetchEvents: async () => {
      const events = await await collection({
        path: 'events',
        builder: (query) =>
          query
            .select('*, notes:event_notes( *, account:accounts (*))')
            .order('created_at', { ascending: false })
      })
      set({ events, loading: false })
    },
    addEvent: (event) => set(({ events }) => ({ events: [...events, event] })),
    addEventNote: (note) => set((state) => ({
      events: state.events.map(event =>
        event.id === note.event_id ? { ...event, notes: [...event.notes, note] } : event
      )
    })),
    updateEvent: (updated) => set((state) => ({
      events: state.events.map(event => 
        event.id === updated.id ? { ...event, ...updated } : event
      )
    })),
    deleteEvent: ({ id }) => set((state) => ({
      events: state.events.filter(event => event.id !== id)
    })),
  }
})

export const useLoading = () => useEventsStore((state) => state.loading)
export const useEvents = () => useEventsStore((state) => state.events)
export const useCurrentEvent = () => {
  const { event_id } = useParams()
  return useEventsStore((state) => state.events.find(event => event.id === event_id)) || {}
}
export const useCustomerEvents = (id) => useEventsStore((state) => state.events.filter(event => event.customer_id === id))
export const useNewEvent = (id) => useEventsStore((state) => state.events.some(event => event.id === id))

export const addEvent = useEventsStore.getState().addEvent
export const addEventNote = useEventsStore.getState().addEventNote
export const updateEvent = useEventsStore.getState().updateEvent
export const deleteEvent = useEventsStore.getState().deleteEvent
export const fetchEvents = useEventsStore.getState().fetchEvents
export default useEventsStore

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Events', useEventsStore);
}
