import { redirect } from 'react-router-dom'

import Customer from 'pages/Customer/Document'
import Modal from 'pages/Customer/Document/Contacts/Modal'

import { upsert, get_form_data } from 'utilities/routes'

const action = async ({ params, request }) => {
  const [ data, section ] = await get_form_data(request)

  switch (section) {
    case 'general':
      upsert('customers', { id: params.id, ...data, owners: data.owners.split(',') })
      break
    case 'update_contact':
      upsert('contacts', { customer_id: params.id, ...data })
      return redirect(`/customers/${params.id}`);
    case 'delete_contact':
      delete('contacts', { id: data.id })
      break
  }

  return null
}

const route = {
  path: ':id',
  element: <Customer />,
  action,
  children: [{
    path: 'contacts/:contact_id',
    element: <Modal open />,
    action,
  }]

}

export default route
