import { useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import Icon from 'components/Icon'

import UserButton from './UserButton'
import UserMenu from './UserMenu'
import OrgButton from './OrgButton'
import OrgMenu from './OrgMenu'
import { IconButton } from '@mui/material'

const Container = styled('div')`
  position: sticky;
  top: 0;
  margin: 0 auto;
  padding: 1rem 2rem;
  z-index: 1000;
  display: flex;
  justify-content: end;
  gap: 1rem;
`

const UserMenus = () => {
  const userMenuRef = useRef()
  const orgMenuRef = useRef()
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const [orgMenuOpen, setOrgMenuOpen] = useState(false)
  const navigate = useNavigate()

  // TODO: Call RPC to change tenant instead.
  // const change_tenant = async () => {
  //   const { data: user_data, error } = await supabase.auth.updateUser({
  //     data: { current_tenant: 'tenant_accodeing' }
  //   })
  // }

  const organisations = [
    {
      id: 1,
      name: 'Accodeing to you',
      logo_url: 'https://accodeing.com/assets/images/plain_logo.png',
    },
  ]
  const organisation = organisations[0]
  const nested_path = window.location.pathname.split('/').length > 3

  return (
    <Container>
      {nested_path && (
        <IconButton
          sx={{ mr: 'auto' }}
          onClick={() => {
            navigate(
              window.location.pathname.split('/').slice(0, -1).join('/'),
              { state: { animate: true } }
            )
          }}
        >
          <Icon name='chevron-left' />
        </IconButton>
      )}
      <OrgButton
        organisation={organisation}
        disable={organisations.length >= 1}
        ref={orgMenuRef}
        open={orgMenuOpen}
        onClick={() => {
          setOrgMenuOpen(true)
        }}
      />
      <UserButton
        ref={userMenuRef}
        open={userMenuOpen}
        onClick={() => {
          setUserMenuOpen(true)
        }}
      />
      <UserMenu
        anchorEl={userMenuRef.current}
        open={userMenuOpen}
        onClose={() => {
          setUserMenuOpen(false)
        }}
      />
      <OrgMenu
        organisations={organisations}
        anchorEl={orgMenuRef.current}
        open={orgMenuOpen}
        onClose={() => {
          setOrgMenuOpen(false)
        }}
      />
    </Container>
  )
}

export default UserMenus
