import { useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  languages,
  highlight as prismjshighlight,
} from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css' //Example style, you can use another
import { useHotkeys } from 'react-hotkeys-hook'

const Container = styled('section')`
  position: relative;
  overflow: hidden;
  &::after {
    border-bottom: 2px solid #74749b;
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  &.focused::after {
    transform: scaleX(1) translateX(0);
  }
`

const Preview = styled('pre')`
  padding: 8.5px 14px;
  margin: 0;
  min-height: calc(4.5rem + 20px);
  font-size: 1rem;
  line-height: 1.5;
  pointer-events: none;
  letter-spacing: normal;
  background: rgba(0, 0, 0, 0.06);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
`

const Textarea = styled('textarea')`
  padding: 8.5px 14px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: normal;
  color: transparent;
  caret-color: #000;
  background: transparent;
  outline: none;
  resize: none;
`

const CodeEditor = ({ code = '', onChange, onSave }) => {
  const [focused, setFocused] = useState(false)
  const ref = useHotkeys(
    'cmd+s, ctrl+s',
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      onSave()
    },
    { enableOnTags: ['TEXTAREA'] }
  )

  const highlighted = prismjshighlight(code, languages.js)

  return (
    <Container className={focused ? 'focused' : ''}>
      <Preview dangerouslySetInnerHTML={{ __html: highlighted + '<br />' }} />
      <Textarea
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        ref={ref}
        autoComplete='off'
        autoCorrect='off'
        spellCheck='false'
        autoCapitalize='off'
        value={code}
        onChange={({ target: { value } }) => onChange(value)}
      />
    </Container>
  )
}

export default CodeEditor
