import { Button, Divider, Typography } from '@mui/material'
import { Fragment } from 'react'

const ColorTest = ({ colorName }) => (
  <>
    <Typography
      variant='h6'
      component='span'
      sx={{ width: '120px', display: 'inline-block' }}
    >
      {colorName.slice(0, 1).toUpperCase() + colorName.slice(1)}
    </Typography>
    <Button color={colorName} variant='text'>
      Text
    </Button>
    <Button color={colorName} variant='contained'>
      Contained
    </Button>
    <Button color={colorName} variant='outlined'>
      Outlined
    </Button>
  </>
)

const Dashboard = () => (
  <>
    <Typography variant='h2' mb={4}>
      Dashboard
    </Typography>

    {['primary', 'secondary', 'tertiary', 'quaternary', 'quinary'].map(
      (colorName, index) => (
        <Fragment key={colorName}>
          <ColorTest colorName={colorName} />
          {index < 4 && <Divider sx={{ mt: 2, mb: 2 }} />}
        </Fragment>
      )
    )}
  </>
)

export default Dashboard
