import { useState, Suspense } from 'react'
import { Await, Outlet, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { Box, Container } from '@mui/material'

import useCustomerHistory from 'hooks/useCustomerHistory'
import preload from 'utilities/preload'

import UserMenu from 'components/UserMenus'
import SideMenu from 'components/SideMenu'
import { LoadingProgress, LoadingError } from 'components/LoadingModals'

const DashboardContent = () => {
  const [open, setOpen] = useState(false)
  const history = useCustomerHistory()
  const location = useLocation()
  const toggleSideMenu = () => {
    setOpen(!open)
  }

  return (
    <Suspense fallback={<LoadingProgress />}>
      <Await
          resolve={preload}
        errorElement={
          <LoadingError />
        }
      >
        <Box sx={{ display: 'flex' }}>
          <SideMenu open={open} toggleSideMenu={toggleSideMenu} history={history} />
          <Box
            component='main'
            id='content'
            sx={{
              position: 'relative',
              backgroundColor: 'background.default',
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
              scrollbarGutter: 'stable',
            }}
          >
            <UserMenu />
            <Container maxWidth='xl' sx={{ pb: 5 }}>
              <AnimatePresence mode='wait' location={location}>
                <Outlet />
              </AnimatePresence>
            </Container>
          </Box>
        </Box>
      </Await>
    </Suspense>
  )
}

export default DashboardContent
