import { Badge, Checkbox, IconButton, Paper, Stack, Tooltip, Typography, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import useSupabase from 'hooks/useSupabase'
import Icon from 'components/Icon'

const OffsetBadge = styled(Badge)`
  position: absolute;
  top: 0;
  right: 0;
`

const Panel = ({ event, versions }) => {
  const supabase = useSupabase()
  const navigate = useNavigate()

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          padding: '.5rem 1rem',
        }}
      >
        <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
          <Typography variant='h6' component='span'>
            {event.title}
          </Typography>
          <Stack direction='row' gap={0}>
            <Checkbox
              checked={!!event.pinned}
              name='pinned'
              inputProps={{ 'aria-label': 'toggle if note is pinned to the top of the list' }}
              icon={<Icon name='thumbtack' sx={{ opacity: 0.5 }} />}
              checkedIcon={<Icon name='thumbtack' sx={{ opacity: 1.0 }} />}
              onClick={async ({ target: { checked } }) => {
                //submit()
                const { error } = await supabase
                  .from('events')
                  .update({ pinned: checked })
                  .eq('id', event.id)
              
                console.log(error)
              }}
            />
            <IconButton onClick={() => navigate(`./${event.id}`, { state: { animate: false}})}>
              <Icon name='edit' />
            </IconButton>
          </Stack>
        </Stack>
        {event.notes}
        <Tooltip title={`${versions} previous ${versions === 1 ? 'version' : 'versions'}`} arrow placement='left'>
          <OffsetBadge
            badgeContent={versions}
            color='info'
            invisible={versions === 0}
          />
        </Tooltip>
      </Paper>
    </>
  )
}

export default Panel
