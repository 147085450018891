import { Button } from '@mui/material'
import { useState } from 'react'

import ImagePreview from './ImagePreview'
import ImageSelect from './ImageSelect'

const ImageUpload = ({ url='', name='image', path = '', onChange }) => {
  const [open, setOpen] = useState(false)
  const [current_url, set_current_url] = useState(url)


  if (url) return 

  return (
    <>
      <input type='hidden' name={name} value={current_url} />
      {url ? (
        <ImagePreview name={name} url={url} onDelete={() => { set_current_url(''); if(onChange) onChange('') }} />
      ) : (
      <>
        <Button onClick={() => setOpen(true)}>Select image</Button>
        <ImageSelect
          open={open}
          path={path || 'public'}
          onSelect={(url) => {
            setOpen(false)
            set_current_url(url)
            if (onChange) onChange(url)
          }}
          onClose={() => setOpen(false)}
        />
      </>
      )}
    </>
  )
}

export default ImageUpload
