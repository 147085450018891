import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { useParams } from 'react-router-dom'
import { collection } from 'utilities/routes'

const useCustomersStore = create((set) => {
  return {
    loading: true,
    customers: [],
    fetchCustomers: async () => {
      const customers = await collection({
        path: 'customers',
        builder: (query) => query.order('name', { ascending: true }),
      })
      set({ customers, loading: false })
    },
    addCustomer: (customer) => set(({ customers }) => ({ customers: [...customers, customer ] })),
    updateCustomer: (updated) => set((state) => ({
      customers: state.customers.map(customer => 
        customer.id === updated.id ? { ...customer, ...updated }  : customer
      )
    })),
    deleteCustomer: ({ id }) => set((state) => ({
      customers: state.customers.filter(customer => customer.id !== id)
    })),
  }
})

export const useLoading = () => useCustomersStore((state) => state.loading)
export const useCustomers = () => useCustomersStore((state) => state.customers)
export const useCustomer = (id) => useCustomersStore((state) => state.customers.find((customer) => customer.id === id))
export const useCurrentCustomer = () => {
  const { id } = useParams()
  return useCustomersStore((state) => state.customers.find((customer) => customer.id === id)) || {}
}

export const addCustomer = useCustomersStore.getState().addCustomer
export const updateCustomer = useCustomersStore.getState().updateCustomer
export const deleteCustomer = useCustomersStore.getState().deleteCustomer
export const fetchCustomers = useCustomersStore.getState().fetchCustomers
export default useCustomersStore

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Customers', useCustomersStore);
}
