import { RouterProvider } from 'react-router-dom'

import router from './router'
import Login from './pages/Login'
//import EnableMFA from './pages/EnableMFA'
import useSession from './hooks/useSession'
import SnackbarStack from 'components/SnackbarStack'

const App = () => {
  const { loading, session } = useSession()

  if (loading) return

  if (!session) {
    return <Login />
  }

  // if (session.mfa != 'aal2') {
  //   return <EnableMFA />
  // }

  return (
    <div className='App' style={{ minHeight: '100vh' }}>
      <RouterProvider router={router} />
      <SnackbarStack />
    </div>
  )
}

export default App
