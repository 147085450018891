import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Switch, Stack, Typography, styled } from '@mui/material'
import { motion } from 'framer-motion'
import { useLocation, useParams, useNavigate, Outlet } from 'react-router-dom'

import { useCustomerEvents } from 'state/useEventsStore'

import generatePinned from './generatePinned'
import Event, { PinnedPanel } from './Event'
import LineAdd from '../../../components/LineAdd'

const Timeline = styled('section')`
  display: grid;
  grid-template-columns: auto 2rem 1fr;
  grid-gap: 2rem 1rem;
`

const TimeToggle = ({ checked, set_checked }) => (
  <Stack direction="column" alignItems="center">
    <Typography>Show times as</Typography>
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="body2">Relative</Typography>
      <Switch
        checked={checked}
        onChange={() => set_checked(!checked)}
        sx={{
          '& .MuiSwitch-switchBase': {
            color: 'var(--mui-palette-secondary-dark)',
          },
        }}
        inputProps={{
          'aria-label': 'Toggle relative/absolute timestamps'
        }}
      />
      <Typography variant="body2">Absolute</Typography>
    </Stack>
  </Stack>
)

const Events = () => {
  const [checked, set_checked] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  const events = useCustomerEvents(id).filter((event) => event.notes)
  const pinned = generatePinned(events)

  return (
    <>
      <motion.div
        key={location.pathname}

        initial={location?.state?.animate ? { x: '50%', opacity: 0.5 } : false}
        animate={{ x: 0, opacity: 1 }}
        exit={location?.state?.animate ? { x: '50%', opacity: 0.5 } : false}
      >
        <Timeline>
          <PinnedPanel event={pinned} />
          <TimeToggle checked={checked} set_checked={set_checked} />
          <div />
          <LineAdd onClick={() => navigate(`/customers/${id}/events/${ uuid() }`, { state: { animate: false } })} />
          {events.map((event) => <Event key={event.id} event={event} relative={!checked} />)}
        </Timeline>
      </motion.div>
      <Outlet />
    </>
  )
}

export default Events
