import { create } from 'zustand'

const useSessionStore = create((set) => ({
  session: null,
  set_session: (session) => set((state) => ({ ...state, session })),
}))

export const useSession = () => useSessionStore((state) => state.session)
export const useSetSession = () => useSessionStore((state) => state.set_session)

export const getSession = () => useSessionStore.getState().session

export default useSession
