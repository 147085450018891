import { useRef, useState } from 'react'
import {
  IconButton,
  styled,
  Typography,
} from '@mui/material'

import Icon from 'components/Icon'

const Header = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr auto;
  margin-bottom: 1rem;
  grid-column-gap: 1rem;
  opacity: 0.75;
`

const SectionHeader = ({ icon, title, subtitle, on_menu_select, menu: Menu, action }) => {
  const ref = useRef()
  const [open, set_open] = useState(false)

  return (
    <Header>
      <Icon
        name={ icon }
        sx={{
          gridRow: '1 / 3',
          alignSelf: 'center',
          fontSize: '2rem',
          color: 'var(--mui-palette-primary-light)',
        }}
        />
      <Typography
        color='background.contrastText'
        component='h2'
        variant='h4'
      >
        {title}
      </Typography>
      <Typography
        color='background.contrastText'
        component='p'
        variant='body2'
      >
        {subtitle}
      </Typography>
      <div style={{ gridArea: '1 / -2 / 3 / -1', alignSelf: 'end' }}>
        {Menu && (
          <>
            <IconButton ref={ref} onClick={() => set_open(true)}>
              <Icon name='bars' />
            </IconButton>
            <Menu
              anchorEl={ref.current}
              open={open}
              debug={false}
              onClose={() => set_open(false)}
              onClick={(action) => {
                on_menu_select(action)
                set_open(false)
              }}
                />
          </>
        )}
        {action && (
          action
        )}
      </div>
    </Header>
  )
}

export default SectionHeader
