import { useState } from 'react'
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'

import Field from 'components/Field'
import Icon from 'components/Icon'

const types = [
  {
    name: 'Email',
    value: 'email',
    icon: 'envelope',
    hint: 'The email address.',
  },
  {
    name: 'Phone',
    value: 'phone',
    icon: 'phone',
    hint: 'Phone number, including country code.',
  },
  {
    name: 'Fax',
    value: 'fax',
    icon: 'fax',
    hint: 'Fax number, including country code.',
  },
  {
    name: 'Mobile',
    value: 'mobile',
    icon: 'mobile',
    hint: 'Mobile number, including country code.',
  },
  {
    name: 'Address',
    value: 'address',
    icon: 'map-marker-alt',
    hint: 'Address, including postal code and city.',
  },
  {
    name: 'Website',
    value: 'website',
    icon: 'globe',
    hint: 'Website, including protocol.',
  },
  {
    name: 'Facebook',
    value: 'facebook',
    icon: 'facebook',
    brand: true,
    hint: 'Facebook profile ID or username.',
  },
  {
    name: 'X/Twitter',
    value: 'twitter',
    icon: 'twitter',
    brand: true,
    hint: 'X/Twitter handle.',
  },
  {
    name: 'Linkedin',
    value: 'linkedin',
    icon: 'linkedin',
    brand: true,
    hint: 'Linkedin profile ID or username.',
  },
  {
    name: 'Instagram',
    value: 'instagram',
    icon: 'instagram',
    brand: true,
    hint: 'Instagram username.',
  },
  {
    name: 'Youtube',
    value: 'youtube',
    icon: 'youtube',
    brand: true,
    hint: 'Youtube channel ID or username.',
  },
  {
    name: 'Github',
    value: 'github',
    icon: 'github',
    brand: true,
    hint: 'Github username.',
  },
]

const AttributeForm = ({
  type = '',
  name = '',
  value = '',
  note = '',
  note_is_warning = false,
  index,
  remove,
}) => {
  const [selected_type, set_selected_type] = useState(type)
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <FormControl fullWidth size='small' sx={{ mt: 2.2 }}>
          <InputLabel id='demo-simple-select-label'>Type</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            defaultValue={type}
            label='Type'
            name={`attributes[${index}].type`}
            onChange={({ target: { value } }) => set_selected_type( value )}
            SelectDisplayProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <MenuItem value='' disabled>
              <Icon name='questionmark' />
              None
            </MenuItem>
            {types.map(({ name, value, icon, brand }) => (
              <MenuItem key={value} value={value}>
                <Icon
                  baseClassName={brand ? 'fa-brands' : undefined}
                  name={icon}
                />
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <Field
          label='Name'
          helperText='Like Work, Home, Mobile, etc.'
          name={`attributes[${index}].name`}
          defaultValue={name}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          label='Value'
          helperText={types.find((t) => t.value === selected_type)?.hint}
          name={`attributes[${index}].value`}
          defaultValue={value}
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          label={note_is_warning ? 'Warning' : 'Note'}
          helperText='Click the icon to toggle between a note and a warning.'
          name={`attributes[${index}].note`}
          defaultValue={note}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Checkbox
                  defaultChecked={!!note_is_warning}
                  name={`attributes[${index}].note_is_warning`}
                  inputProps={{ 'aria-label': 'toggle note type' }}
                  icon={<Icon sx={{ color: 'var(--mui-palette-info-dark)' }} name='info-circle' />}
                  checkedIcon={<Icon sx={{ color: 'var(--mui-palette-warning-dark)' }} name='exclamation-triangle' />}
                  sx={{ left: '-.5rem' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={1} sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'end',
        marginTop: 2,
      }}>
        <IconButton aria-label='delete' onClick={remove}>
          <Icon name='trash' />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default AttributeForm
