import { forwardRef } from 'react'
import { NavLink as NavLinkBase, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Drawer as MuiDrawer,
  ListItemButton as MuiListItemButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'

import Icon from 'components/Icon'

const NavLink = forwardRef((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={({ isActive }) =>
      isActive ? props.className + ' Mui-selected' : props.className
    }
  />
))

NavLink.displayName = 'NavLink'

const ListItemButton = styled(MuiListItemButton)`
  & .MuiListItemIcon-root {
    min-width: 42px;
  }
`

const menu = [
  { icon: 'chart-tree-map', text: 'Dashboard', to: '/' },
  { icon: 'store', text: 'Customers', to: 'customers' },
  { icon: 'bags-shopping', text: 'Products', to: 'products' },
  { icon: 'people-simple', text: 'Users', to: '', disabled: true },
  { icon: 'chart-line-up', text: 'Reports', to: '', disabled: true },
  { icon: 'puzzle', text: 'Integrations', to: '', disabled: true },
]

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'sideMenuWidth',
})(({ theme, open, sideMenuWidth }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: sideMenuWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },
    }),
  },
}))

const SideMenu = ({ open, toggleSideMenu, history }) => {
  const navigate = useNavigate()

  return (
    <StyledDrawer variant='permanent' open={open} sideMenuWidth={240}>
      <Toolbar
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
          overflow: 'hidden',
          // This point on is just the open/close transition
          height: open ? '0px' : '64px',
          minHeight: open ? '0px !important' : '64px',
          opacity: open ? '0' : '1',
          ...(open
            ? {
                transition: theme.transitions.create(
                  ['min-height', 'height', 'opacity'],
                  {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }
                ),
              }
            : {
                transition: theme.transitions.create(
                  ['min-height', 'height', 'opacity'],
                  {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }
                ),
              }),
        })}
      >
        <IconButton onClick={toggleSideMenu}>
          <Icon
            className={open ? 'fa-rotate-180' : ''}
            name={'chevron-right'}
          />
        </IconButton>
      </Toolbar>
      <Stack direction='row' alignItems='center'>
        <img
          style={{ padding: 'calc((54px - 36px) / 2)' }}
          src='/android-icon-36x36.png'
        />
        <Typography component='h1' variant='h6' color='inherit' noWrap>
          amain.app
        </Typography>
        <IconButton sx={{ marginLeft: 'auto' }} onClick={toggleSideMenu}>
          <Icon
            className={open ? 'fa-rotate-180' : ''}
            name={'chevron-right'}
          />
        </IconButton>
      </Stack>
      <Divider />
      <List component='nav'>
        {menu.map(({ icon, text, to, ...props }) => (
          <ListItemButton
            key={text}
            {...props}
            component={NavLink}
            end={true}
            to={to}
          >
            <ListItemIcon>
              <Icon name={icon} />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        ))}
        <Divider sx={{ my: 1 }} />
        {history.length > 0 && (
          <ListSubheader title='Recent customers' component='div'>
            <span
              className='fa-stack fa-fw'
              style={{
                zoom: 1.3,
                width: '16px',
                marginRight: '16px',
              }}
            >
              <i
                className='fa-solid fa-store fa-stack-1x'
                style={{
                  top: '-4px',
                  zoom: '.9',
                  left: '-4px',
                  color: '#ddd',
                  clipPath:
                    'polygon(35% 100%, 35% 35%, 100% 35%, 100% 0, 0 0, 0 100%)',
                }}
              ></i>
              <i
                className='fa-solid fa-store fa-stack-1x'
                style={{
                  top: '-2.3px',
                  zoom: '.95',
                  left: '-2px',
                  color: '#999',
                  clipPath:
                    'polygon(35% 100%, 35% 35%, 100% 35%, 100% 0, 0 0, 0 100%)',
                }}
              ></i>
              <i
                className='fa-solid fa-store fa-stack-1x'
                style={{ color: '#666' }}
              ></i>
            </span>
            Recent customers
          </ListSubheader>
        )}
        {history.map((customer) => (
          <ListItemButton
            key={customer.number}
            component={NavLink}
            to={`/customers/${customer.id}`}
            // onClick={() => navigate(`/customers/${customer.id}`)}
            style={{ paddingLeft: '8px' }}
          >
            <Tooltip title={customer.name} arrow placement='right'>
              <ListItemAvatar>
                <Avatar
                  alt={`${customer.name}'s logotype`}
                  src={customer.logo_url}
                />
              </ListItemAvatar>
            </Tooltip>
            <ListItemText
              title={customer.legal_name}
              primary={customer.name}
              secondary={customer.number}
            />
          </ListItemButton>
        ))}
      </List>
    </StyledDrawer>
  )
}

export default SideMenu
