import { fetchCustomers } from 'state/useCustomersStore'
import { fetchProducts } from 'state/useProductsStore'
import { fetchContacts } from 'state/useContactsStore'
import { fetchEvents } from 'state/useEventsStore'

const preload = Promise.all([
  fetchCustomers(),
  fetchContacts(),
  fetchProducts(),
  fetchEvents(),
])

export default preload
