import Summary from "./Summary"
import Separator from "./Separator"
import Panel from "./Panel"
import PinnedPanel from "./PinnedPanel"

export { PinnedPanel }

const Event = ({ event: partial_event, relative }) => {
  const number_of_notes = partial_event?.notes?.length || 0
  const latest = partial_event.notes[number_of_notes - 1]
  const event = {
    ...partial_event,
    updated_at: partial_event.created_at,
    ...latest,
  }

  return (
    <>
      <Summary event={event} versions={number_of_notes - 1} relative={relative} />
      <Separator event={event} />
      <Panel event={event} versions={number_of_notes - 1} />
    </>
  )
}

export default Event
