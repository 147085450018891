import { v4 as uuid } from 'uuid'
import {
  Card,
  CardContent,
  CardHeader,
  FormLabel,
  IconButton,
} from '@mui/material'
import Field from 'components/Field'
import Radio from 'components/RadioGroup'
import Icon from 'components/Icon'
import SectionHeader from 'components/SectionHeader'

import CommonFields from '../CommonFields'
import { Text } from './Text'
import { Number } from './Number'
import { Range } from './Range'
import { RadioGroup } from './RadioGroup'
import { CheckboxGroup } from './CheckboxGroup'
import { String } from './String'

const Options = ({ onChange, attributes }) => {
  const update = (change) => onChange({ ...attributes, ...change })

  switch (attributes.type) {
    case 'text':
      return <Text {...attributes} onChange={update} />
    case 'number':
      return <Number {...attributes} onChange={update} />
    case 'range':
      return <Range {...attributes} onChange={update} />
    case 'radio':
      return <RadioGroup {...attributes} onChange={update} />
    case 'checkbox':
      return <CheckboxGroup {...attributes} onChange={update} />
    default:
      return <String {...attributes} onChange={update} />
  }
}

const defaultOptions = {
  string: {},
  number: { min: 0, step: 1 },
  text: {},
  range: {},
  radio: {},
  checkbox: {},
}

const SimpleInput = ({
  id = uuid(),
  attributes: { default_value = '', type = 'string' },
  attributes,
  onChange,
  onDelete,
  ...commonOptions
}) => (
  <>
    <SectionHeader
      icon='brackets-curly'
      title={commonOptions.name || 'Input'}
      subtitle='Define a new variable that will be used when defining the product instance for the end user.'
    />
  <Card id={id}>
    <CardContent>
      <CommonFields {...commonOptions} onChange={onChange} />
      <Field
        label='Default value'
        value={default_value}
        helperText='Optional aupply a default value for the field'
        update={(value) =>
          onChange({ attributes: { ...attributes, default_value: value } })
        }
      />
      <Radio
        label='Input type'
        value={type}
        onChange={(value) =>
          onChange({
            attributes: {
              ...attributes,
              type: value,
              ...defaultOptions[value],
            },
          })
        }
        options={[
          { value: 'string', label: 'String' },
          { value: 'text', label: 'Text' },
          { value: 'number', label: 'Number' },
          { value: 'range', label: 'Range' },
          { value: 'radio', label: 'Radio group' },
          { value: 'checkbox', label: 'Checkboxes' },
        ]}
      />
      <br />
      <FormLabel>Type specific attributes</FormLabel>
      <Options
        attributes={attributes}
        onChange={(change) =>
          onChange({ attributes: { ...attributes, ...change } })
        }
      />
    </CardContent>
    </Card>
  </>
)

export default SimpleInput
