import { v4 as uuid } from 'uuid'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, IconButton } from '@mui/material'

import SectionHeader from 'components/SectionHeader'
import Icon from 'components/Icon'
import { useCustomerContacts } from 'state/useContactsStore'

import Contact from './Contact'

const Contacts = ({ expanded, set_expanded }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const contacts = useCustomerContacts( id )

  return (
    <div>
      <SectionHeader
        id='contacts'
        title='Contacts'
        subtitle='Owners, technical contacts and other stakeholders'
        icon='address-book'
        action={
          <IconButton
            onClick={() => set_expanded(!expanded)}
            aria-expanded={expanded}
            aria-label='show more'
            size='small'
          >
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </IconButton>
        }
      />
      <Card>
        <Collapse in={!expanded} timeout='auto' unmountOnExit>
          <CardHeader
            subheader={`Expand to display ${contacts.length} ${contacts.length === 1 ? 'contact' : 'contacts'}`}
          />
        </Collapse>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          {contacts.map((contact, index) => (
            <CardContent
              key={contact.id}
              sx={{
                bgcolor:
                  index % 2 === 0 ? 'var(--mui-palette-grey-100)' : 'inherit',
                borderTop:
                  index % 2 === 0 ? '1px solid var(--mui-palette-grey-300)' : 0,
                borderBottom:
                  index % 2 === 0 ? '1px solid var(--mui-palette-grey-300)' : 0,
              }}
            >
              <Contact contact={contact} index={index} />
            </CardContent>
          ))}
        </Collapse>
        <CardActions sx={{ justifyContent: 'end'}}>
          <Button color="secondary" size="small" onClick={() => navigate(`contacts/${ uuid() }`) }>Add contact</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default Contacts
