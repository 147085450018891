import { createBrowserRouter } from 'react-router-dom'

import { getProducts, getProduct, getProductAddons } from 'state/useProductsStore'

import customers from './routes/customers.js'
import Dashboard from './pages/Dashboard'
import Error from './pages/Error'
import Products from './pages/Product/Collection'
import Product from './pages/Product/Document'
import Template from './template'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Template />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      customers,
      {
        path: 'products',
        children: [
          {
            index: true,
            element: <Products />,
          },
          {
            path: ':id',
            element: <Product />,
          },
        ],
      },
    ],
  },
])

export default router
