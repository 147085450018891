import { Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Icon from 'components/Icon'

import Note from './Note'
import icon_for from './icon_for'
import url_for from './url_for'
import AttributeButton from './AttributeButton'
import AttributeContainer from './AttributeContainer'

const Attribute = ({ type, name, value, note, note_is_warning, odd }) => {
  const url = url_for({ type, value })

  return (
    <Grid item xs={6}>
      <a href={url} target='_blank' style={{ textDecoration: 'none' }} rel="noreferrer">
        <AttributeContainer odd={odd}>
          <AttributeButton container spacing={2}>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon
                sx={{
                  fontSize: '2rem',
                  color: 'var(--mui-palette-text-disabled)',
                }}
                fontSize='large'
                name={icon_for(type)}
              />
            </Grid>
            <Grid item xs={9}>
              <Stack>
                <Typography variant='caption' color='text.disabled'>
                  {name}
                </Typography>
                <Typography variant='body1'>{value}</Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Note note={note} warning={note_is_warning} />
            </Grid>
          </AttributeButton>
        </AttributeContainer>
      </a>
    </Grid>
  )
}

export default Attribute
